<template>
  <div class="resetPassword">
    <div class="topBar stickTop">
      <div class="logoIcon">
        <img src="images/logo-white.png" alt="" />
      </div>
      <div class="logoName">
        <span class="name1">Hotspex</span>
        <span class="name2">STUDIO</span>
      </div>
    </div>
    <div class="centerContent">
      <section class="formContainer">
        <div v-if="resetSuccess === -1">
          <div class="formTop twocol">
            <h2>Reset Password</h2>
          </div>
          <div class="formBody">
            <p>
              Please enter and confirm your new password below, then click
              submit button.
            </p>
            <!--  -->
            <form>
              <!-- password -->
              <div class="field">
                <!-- password validation hint -->
                <div v-show="!pswdValidated" class="hintBox">
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': pswd.length >= 8 }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="pswd.length >= 8"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!(pswd.length >= 8)"
                    />
                    Longer than 8 characters
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_uppercase }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_uppercase"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_uppercase"
                    />
                    Has a capital letter
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_number }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_number"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_number"
                    />
                    Has a number
                  </p>
                  <p
                    class="frmValidation"
                    :class="{ 'frmValidation--passed': has_special }"
                  >
                    <font-awesome-icon
                      class="frmIcon"
                      icon="check"
                      v-show="has_special"
                    />
                    <font-awesome-icon
                      class="frmIcon"
                      icon="times"
                      v-show="!has_special"
                    />
                    Has a special character
                  </p>
                </div>
                <div class="gapx2"></div>
                <label class="lineLable">New Password</label>
                <!-- first password -->
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd"
                    @input="passwordCheck"
                    required
                    class="fullWidth"
                  />
                  <span class="icon fixRight validated" v-show="pswdValidated"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
                <div class="gap"></div>
                <!-- second password -->
                <label class="lineLable">Confirm Password</label>
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd2"
                    required
                    class="fullWidth"
                    :class="{ error: pswd !== pswd2 }"
                  />
                  <span
                    class="icon fixRight validated"
                    v-show="pswdValidated && pswd === pswd2"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
                <div class="gap"></div>
                <!-- show password -->
                <div>
                  <label class="sm-text checkbox-label">
                    <input type="checkbox" v-model="pswdVisible" />
                    Show Password
                  </label>
                </div>
              </div>
              <div>
                <button
                  class="button darkgreyBtn"
                  :disabled="!formValidated"
                  @click.prevent="updatePassword"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- form feedback success -->
        <div class="feedback" :class="{ expand: resetSuccess === 1 }">
          <h2>Congratulation!</h2>
          <p>
            Your password reset was successful and you can now
            <router-link :to="{ name: 'login' }" class="blueTextLink"
              >login</router-link
            >
          </p>
        </div>
        <!-- form feedback fail -->
        <div class="feedback" :class="{ expand: resetSuccess === 0 }">
          <h2>Oops!</h2>
          <p class="field">
            There are something wrong. Please try to
            <router-link :to="{ name: 'forgot-password' }" class="blueTextLink"
              >reset your password</router-link
            >
            again or contact
            <a href="mailto:support@hotspexstudio.com" class="blueTextLink"
              >support@hotspexstudio.com</a
            >
            for assistance.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
// import * as jsUtils from "@/utils/jsUtils.js";
export default {
  data() {
    return {
      pswd: "",
      pswd2: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      feedback: "",
      pswdVisible: false,
      pswdValidated: false,
      email: "vivian.liu@hotspex.com",
      token: "xxx",
      resetSuccess: -1,
    };
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
    formValidated() {
      return this.pswdValidated && this.pswd === this.pswd2;
    },
  },
  methods: {
    passwordCheck() {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      this.has_number = /\d/.test(this.pswd);
      this.has_uppercase = /[A-Z]/.test(this.pswd);
      this.has_special = format.test(this.pswd);

      if (
        this.pswd.length >= 8 &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.pswdValidated = true;
        console.log("pass validate");
      } else {
        this.pswdValidated = false;
      }
    },
    updatePassword() {
      const pswdPromise = new Promise((resolve) => {
        wsUtils.UpdatePassword(resolve, {
          Email: this.email,
          Password: this.pswd,
          token: this.token,
        });
      });

      pswdPromise.then((data) => {
        console.log(data);
        if (data.Success) {
          // password updated;
          this.feedback = "";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  background: url("../../public/images/logBG4.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;

  display: grid;
  grid-template-rows: auto 1fr;
}

.topBar {
  background-color: var(--hsred);
  height: 75px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .logoIcon {
    background-color: var(--hsgrey);
    height: 100%;
    padding: 10px;

    img {
      max-height: 100%;
      display: block;
      margin: 0;
    }
  }

  .logoName {
    color: #fff;

    display: flex;
    flex-direction: column;
    padding-left: 10px;
    text-align: left;

    .name1 {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .name2 {
      font-size: 18px;
      letter-spacing: 3px;
      font-weight: 300;
    }
  }
}

form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.field {
  margin-bottom: 2em;

  .lineLable {
    text-transform: uppercase;
    display: block;
    margin-bottom: 8px;
    font-size: 0.85em;
  }

  .iconInput {
    position: relative;
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
  }

  input.fullWidth {
    width: 100%;
    padding: 0 5px;
    height: 32px;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }

  .hintBox {
    border: 1px solid var(--lineGrey);
    margin-bottom: 0.7em;
    padding-left: 8px;
    background-color: var(--lightGrey100);
  }
}

.validated {
  color: var(--succeedGreen) !important;
}

.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}

.frmIcon {
  color: var(--hsred);
}
.frmValidation--passed .frmIcon {
  color: var(--succeedGreen);
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  padding: 0 20px;
  text-align: center;
  max-height: 0;
  line-height: 1.8em;
  font-size: 1.2em;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
  padding: 20px;
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  padding: 0;
  text-align: center;
  max-height: 0;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
  padding: 20px;
}
</style>
